import {render, renderException, renderInfo} from "./LoaderUI.js";

document.body.innerHTML = ""; // To remove loader <script>
render();
renderInfo("Loading Application...");

import("Application.js").then(module => {
	module.App.run();
}).catch(ex => {
	console.error(ex);
	renderException(ex);
});
